import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/system';

import { Container, Item } from 'Components/Grid';
import TextArea from 'Components/InputHandler/components/TextArea';

import colors from '../../../config/theme/colors';

import { IArchiveObject, IClinicalTextObject } from './helpers';
import { INeuroArchiveTemplate } from 'neuro-data-structures';
import InfoPopper from 'Components/InfoPopper';

const Section = styled('div')({
  marginBottom: '2rem',
});

const Title = styled('div')({
  fontSize: '1.6rem',
  fontWeight: 600,
});

const TextInputArea = styled('div')({
  borderRadius: '0.4rem',
  padding: '0.3rem 1rem',
  margin: '2rem 0rem 2rem 2rem',
  width: '70%',
  border: `0.1rem solid ${colors.lightGray}`,
});

const Paragraph = styled('div')({
  fontSize: '1.6rem',
  paddingLeft: '2rem',
  marginBottom: '2rem',
  whiteSpace: 'pre-wrap',
});

// Collapsing text area for submitting additional information into texts
const CollapsingTextInputArea = ({
  contentText,
  onChangeText,
  sectionIndex,
  contentIndex,
  editing,
  headerText,
  infoText,
}: {
  contentText?: string | null;
  onChangeText: (text: string, sectionIndex: number, contentIndex: number) => void;
  sectionIndex: number;
  contentIndex: number;
  editing: boolean;
  headerText: string;
  infoText: string | null;
}) => {
  const [open, setOpen] = React.useState(!!(contentText && contentText !== ''));

  let textAreaElement: HTMLElement | null = null;
  const onInput = (thisElement: HTMLElement | null) => {
    if (!thisElement || !thisElement.style) return;
    thisElement.style.height = 'auto';
    thisElement.style.height = thisElement.scrollHeight + 'px';
  };

  React.useEffect(() => {
    textAreaElement = document.getElementById(sectionIndex + contentIndex + 'newInfo');
    if (textAreaElement) {
      textAreaElement.setAttribute('style', 'height:' + textAreaElement.scrollHeight + 'px;overflow-y:hidden;');
      textAreaElement.addEventListener('input', () => onInput(textAreaElement), false);
    }
    return () => {
      textAreaElement?.removeEventListener('input', () => onInput(textAreaElement));
    };
  }, [editing]);

  return editing ? (
    <TextInputArea>
      <div onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
        <Container>
          <Item style={{ width: '3rem' }}>
            {open ? <ArrowDropDown style={{ display: 'block' }} /> : <ArrowRight style={{ display: 'block' }} />}
          </Item>
          <Item xs={true}>{headerText}</Item>
          {infoText && <InfoPopper content={<>{infoText}</>} disableOnCloseProp />}
        </Container>
      </div>
      <Collapse in={open}>
        <div style={{ margin: '1rem 3rem 1rem 3rem' }}>
          <TextArea
            type="TextArea"
            name={sectionIndex + contentIndex + 'newInfo'}
            id={sectionIndex + contentIndex + 'newInfo'}
            value={contentText || undefined}
            onChange={(e: TOnChangeValues) => {
              const value = e[Object.keys(e)[0]] as string | undefined | null;
              if (contentText === value) return;
              if (value || value === '') onChangeText(value, sectionIndex, contentIndex);
            }}
            editing={true}
            placeholder={headerText}
            fullWidth={true}
          />
        </div>
      </Collapse>
    </TextInputArea>
  ) : !editing && contentText && contentText !== '' ? (
    <TextInputArea>
      <div style={{ whiteSpace: 'pre-wrap' /* Enable /n and leading white-spaces in text */ }}>{contentText}</div>
    </TextInputArea>
  ) : null;
};

/**
 * Make displayable component from clinical document contents and possible text changes
 */
const ClinicalDocElements = ({
  clinicalDocument,
  textContentNew,
  setTextContentNew,
  fm,
  editing,
  template,
}: IClinicalDocElements): JSX.Element | null => {
  const content = clinicalDocument.content;

  const onChangeContentText = (text: string, sectionIndex: number, contentIndex: number) => {
    if (!textContentNew || !setTextContentNew) return;
    const filterPrevious = textContentNew.filter(
      (f) => !(f.sectionIndex === sectionIndex && f.contentIndex === contentIndex),
    );
    setTextContentNew([...filterPrevious, { sectionIndex, contentIndex, text }]);
  };

  const getContentText = (sectionIndex: number, contentIndex: number) =>
    (textContentNew || []).find((text) => text.sectionIndex === sectionIndex && text.contentIndex === contentIndex)
      ?.text;

  const templateSection = (docTitleCode: string) =>
    template.content.sections.find((sec) => sec.titleCode === docTitleCode);

  const templateTextcontentInfotext = (docTitleCode: string, textContentIndex: number) =>
    templateSection(docTitleCode)?.textContent[textContentIndex]?.infoText;

  return (
    <>
      {/* Patient status <Section>
        <Title>{content.titleText}</Title>
      </Section> */}
      {content.sections.map((section, sectionIndex) => {
        if (section.renderRule === 'not-empty' && section.textContent.every((c) => c === null))
          return <React.Fragment key={sectionIndex}></React.Fragment>;
        return (
          <Section key={section.titleText}>
            <Title>{section.titleText}</Title>
            {section.textContent.map(
              (textContent, contentIndex) =>
                textContent && (
                  <React.Fragment key={contentIndex}>
                    {textContent?.editable ? (
                      <CollapsingTextInputArea
                        contentText={getContentText(sectionIndex, contentIndex) || textContent.text}
                        onChangeText={onChangeContentText}
                        sectionIndex={sectionIndex}
                        contentIndex={contentIndex}
                        editing={editing}
                        headerText={fm('patientRecords.enterMoreInformation')}
                        infoText={templateTextcontentInfotext(section.titleCode, contentIndex) ?? null}
                      />
                    ) : (
                      <Paragraph>{textContent?.text}</Paragraph>
                    )}
                  </React.Fragment>
                ),
            )}
          </Section>
        );
      })}
    </>
  );
};

interface IClinicalDocElements {
  clinicalDocument: IArchiveObject;
  textContentNew?: IClinicalTextObject[];
  setTextContentNew?: (data: IClinicalTextObject[]) => void;
  fm: (id: string) => string;
  editing: boolean;
  template: INeuroArchiveTemplate;
}

export default ClinicalDocElements;
