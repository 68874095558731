import { styled } from '@mui/material';
import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import SyncStatus from './SyncStatus';
import { AlertsPopover } from './AlertsNotificationIcon';
import colors from '../../../config/theme/colors';
import { useAppSelector } from 'Store/index';
import { alerts } from '../../../config/generalSettings';

const StyledIconsArea = styled(Item, {
  shouldForwardProp: (prop) => prop !== 'iconSize' && prop !== 'bgColorAlternate',
})(({ iconSize, bgColorAlternate }: { iconSize?: number; bgColorAlternate?: boolean }) => ({
  margin: '0 1rem',
  padding: '0 0.6rem',
  borderRadius: '5rem',
  height: iconSize ? iconSize + 1 + 'rem' : '3rem',
  backgroundColor: bgColorAlternate ? colors.appBlue.default : '#313E74',
  border: bgColorAlternate ? '0.1rem solid white' : 'none',
}));
const StyledIconsContainer = styled(Container)({
  columnGap: '0.5rem',
});

const SyncAlertIconArea = ({ iconSize = 2, bgColorAlternate }: SyncAlertIconArea) => {
  const session = useAppSelector((state) => state.session);
  const documents = useAppSelector((state) => state.documents.sortedAndMergedDocuments) || [];
  const myAppDocs = useAppSelector((state) => state.myapp.sortedAndMergedDocuments) || [];

  const enabledAlerts =
    session?.platforms?.selected && Array.isArray(documents) && documents.length > 0
      ? alerts[session.platforms?.selected]?.filter((a) => a.rule(documents, myAppDocs)) || []
      : [];
  return (
    <StyledIconsArea iconSize={iconSize} bgColorAlternate={bgColorAlternate}>
      <StyledIconsContainer alignItems={'center'} style={{ height: '100%' }}>
        <SyncStatus iconSize={iconSize} />
        <AlertsPopover iconSize={iconSize} alerts={enabledAlerts} alternateStyle={bgColorAlternate} />
      </StyledIconsContainer>
    </StyledIconsArea>
  );
};

interface SyncAlertIconArea {
  iconSize?: number;
  bgColorAlternate?: boolean;
}

export default SyncAlertIconArea;
