import * as React from 'react';
import { fm } from 'Components/FormatMessage';
import InputHandler from 'Components/InputHandler';
import colors from '../../../config/theme/colors';
import ItemList from 'Components/ItemList';
import SearchDialog from 'Components/SearchDialog';
import { atcCodesByPlatform } from 'Routes/Medication/Document/config';
import ActionButton from 'Components/ActionButton';
import { styled } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import { nowPartialDate, subtractYears } from 'neuro-utils';

const ClearIconStyled = styled(ClearIcon)({
  display: 'block',
  width: '3rem',
  color: `${colors.secondaryText}`,
  cursor: 'pointer',
  '&:hover': {
    color: 'red',
  },
});

export type TESSearchFieldNames =
  // Common
  | 'platform'
  | 'gender'
  | 'age'
  | 'includeDeceased'
  | 'diagnosis'
  | 'diagnosisDate'
  | 'typeOfDisease' // MS
  | 'medication'
  // MS
  | 'relapseDuringLast24Months'
  | 'relapseDuringLast12Months'
  | 'numberOfRelapses'
  | 'edss'
  | 'edssIncreasedDuringLast24Months'
  | 'edssIncreasedDuringLast12Months'
  | 'spmsCriteria'
  | 'patientAgeOnDg'
  // Parkinson
  | 'currentLEDD'
  | 'dbsTreatment'
  | 'thalamotomyOrHIFU'
  | 'currentHoehnYahr'
  // S & R
  | 'professionalDriving'
  | 'treatment'
  | 'latestBMI';

export type TTreatmentType = {
  treatment?: Array<
    | 'patientDoesNotWantRespiratorySupportTherapy'
    | 'oxygenTherapy'
    | 'hfncTherapy'
    | 'papTherapy'
    | 'madTherapy'
    | 'surgicalTreatment'
    | 'postureTreatment'
  >;
  status?: 'noCropping' | 'ongoing' | 'ended';
};

export type TMedicationType = {
  medication?: 'anyMedication' | 'noMedication' | 'specificMedication' | 'specificAtc';
  specificMedication?: 'noCropping' | Array<string>;
  specificAtc?: 'noCropping' | Array<string>;
  status?: 'noCropping' | 'notInUse' | 'inUse' | 'earlierInUse';
};

export type TESSearchFields = {
  gender: 'noCropping' | 'male' | 'female';
  age: 'noCropping' | { minAge?: number | undefined; maxAge?: number | undefined };
  includeDeceased: 'yes' | 'no';
  diagnosis: 'noCropping' | Array<TESDiagnosisOptions>;
  diagnosisDate: 'noCropping' | { diagnosisMinDate: PartialDate | null; diagnosisMaxDate: PartialDate | null };
  typeOfDisease: 'noCropping' | Array<'RR' | 'SP' | 'PP' | 'notSpecified'>; // MS
  medication: 'noCropping' | Array<TMedicationType>;
  // MS
  relapseDuringLast24Months: 'noCropping' | 'yes' | 'no';
  relapseDuringLast12Months: 'noCropping' | 'yes' | 'no';
  numberOfRelapses?: 'noCropping' | '1' | '2' | '3' | 'more';
  edss: 'noCropping' | Array<number>;
  edssIncreasedDuringLast24Months: 'noCropping' | 'yes' | 'no';
  edssIncreasedDuringLast12Months: 'noCropping' | 'yes' | 'no';
  spmsCriteria: 'noCropping' | Array<'expand' | 'lorscheider'>;
  patientAgeOnDg: 'noCropping' | number;
  // Parkinson
  currentLEDD: 'noCropping' | { minLEDD?: number | undefined; maxLEDD?: number | undefined };
  dbsTreatment: 'noCropping' | Array<'ongoing' | 'ended'>;
  thalamotomyOrHIFU: 'noCropping' | Array<'thalamotomyDone' | 'hifuDone'>;
  currentHoehnYahr: 'noCropping' | Array<number>;
  // S & R
  professionalDriving: 'noCropping' | true;
  treatment: 'noCropping' | Array<TTreatmentType>;
  latestBMI: 'noCropping' | { minBMI: number | undefined; maxBMI: number | undefined };
};

export type TESSearchField = {
  name: string;
  title?: string;
  type:
    | 'Radio'
    | 'Checkbox'
    | 'CheckboxSingle'
    | 'MultiSelect'
    | 'PartialDate'
    | 'RangeSlider'
    | 'Custom'
    | 'NumberField';
  options?: Array<string | number>;
  optionFormatter?: (name: string | number) => string | JSX.Element;
  option?: string;
  min?: number;
  max?: number;
  tooltips?: Array<{ index: number; message: JSX.Element }>;
  style?: { [key: string]: string | number };
  condition?: boolean;
  showRangeText?: boolean;
  marks?: { value: number }[];
  dateHook?: {
    dateHookFloor?: PartialDate | undefined;
    dateHookCeiling?: PartialDate | undefined;
  };
  placeholder?: string;
};

export type TESDiagnosisOptions =
  // MS
  | 'keo'
  | 'radiologicallyIsolatedSyndrome'
  | 'ms'
  | 'nmosd'
  // Parkinson
  | 'G20'
  | 'G90.3'
  | 'G23.2'
  | 'G23.3'
  | 'G23.1'
  | 'G25.9'
  | 'G31.0'
  | 'F02.8*G31.8'
  | 'F00.2*G30.8'
  | 'F02.0*G31.0'
  | 'G25'
  | 'G25.0'
  | 'G25.1#'
  | 'G25.2'
  | 'G25.3#'
  | 'G25.4#'
  | 'G25.5'
  | 'G25.6#'
  | 'G25.8'
  // S & R
  | 'sleepApneaSuspicion'
  | 'respiratoryFailureSuspicion'
  | 'G47.3'
  | 'J96.1'
  | 'J96.9';

/**
 * Extended search field specifications
 */
export const extendedSearchFields = (searchFields?: TESSearchFields): { [platform: string]: TESSearchField[] } => ({
  ms: [
    {
      name: 'gender',
      type: 'Radio',
      options: ['male', 'female'],
    },
    {
      name: 'age',
      type: 'RangeSlider',
      min: 0,
      max: 100,
      showRangeText: true,
      marks: [{ value: 33 }, { value: 66 }],
    },
    {
      name: 'diagnosis',
      type: 'Checkbox',
      options: ['keo', 'radiologicallyIsolatedSyndrome', 'ms', 'nmosd'],
    },
    {
      name: 'diagnosisDate',
      type: 'Custom',
    },
    {
      name: 'patientAgeOnDg',
      type: 'RangeSlider',
      min: 0,
      max: 100,
      showRangeText: true,
      marks: [{ value: 33 }, { value: 66 }],
    },
    {
      name: 'typeOfDisease',
      type: 'Checkbox',
      options: ['RR', 'SP', 'PP', 'cantDefine'] as TMS[],
      condition: Array.isArray(searchFields?.diagnosis) && searchFields?.diagnosis.includes('ms'),
    },
    {
      name: 'medication',
      type: 'Custom',
      title: 'diseaseModifyingMedication',
    },
    {
      name: 'relapseDuringLast24Months',
      type: 'Radio',
      options: ['yes', 'no'],
    },
    {
      name: 'relapseDuringLast12Months',
      type: 'Custom',
      condition: searchFields?.relapseDuringLast24Months !== 'no',
    },
    {
      name: 'edss',
      type: 'MultiSelect',
      options: [0, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10],
      tooltips: [
        { index: 0, message: fm('neurologicalStatusAndEDSS.edssStep.opts.0') },
        { index: 1, message: fm('neurologicalStatusAndEDSS.edssStep.opts.1.0') },
        { index: 2, message: fm('neurologicalStatusAndEDSS.edssStep.opts.1.5') },
        { index: 3, message: fm('neurologicalStatusAndEDSS.edssStep.opts.2.0') },
        { index: 4, message: fm('neurologicalStatusAndEDSS.edssStep.opts.2.5') },
        { index: 5, message: fm('neurologicalStatusAndEDSS.edssStep.opts.3.0') },
        { index: 6, message: fm('neurologicalStatusAndEDSS.edssStep.opts.3.5') },
        { index: 7, message: fm('neurologicalStatusAndEDSS.edssStep.opts.4.0') },
        { index: 8, message: fm('neurologicalStatusAndEDSS.edssStep.opts.4.5') },
        { index: 9, message: fm('neurologicalStatusAndEDSS.edssStep.opts.5.0') },
        { index: 10, message: fm('neurologicalStatusAndEDSS.edssStep.opts.5.5') },
        { index: 11, message: fm('neurologicalStatusAndEDSS.edssStep.opts.6.0') },
        { index: 12, message: fm('neurologicalStatusAndEDSS.edssStep.opts.6.5') },
        { index: 13, message: fm('neurologicalStatusAndEDSS.edssStep.opts.7.0') },
        { index: 14, message: fm('neurologicalStatusAndEDSS.edssStep.opts.7.5') },
        { index: 15, message: fm('neurologicalStatusAndEDSS.edssStep.opts.8.0') },
        { index: 16, message: fm('neurologicalStatusAndEDSS.edssStep.opts.8.5') },
        { index: 17, message: fm('neurologicalStatusAndEDSS.edssStep.opts.9.0') },
        { index: 18, message: fm('neurologicalStatusAndEDSS.edssStep.opts.9.5') },
        { index: 19, message: fm('neurologicalStatusAndEDSS.edssStep.opts.10') },
      ],
      style: { display: 'flex', justifyContent: 'center', width: '2rem' },
    },
    {
      name: 'edssIncreasedDuringLast24Months',
      type: 'Radio',
      options: ['yes', 'no'],
    },
    {
      name: 'edssIncreasedDuringLast12Months',
      type: 'Radio',
      options: ['yes', 'no'],
      condition: searchFields?.edssIncreasedDuringLast24Months !== 'no',
    },
    {
      name: 'spmsCriteria',
      type: 'Checkbox',
      options: ['expand', 'lorscheider'],
    },
    {
      name: 'includeDeceased',
      type: 'Radio',
      options: ['yes', 'no'],
    },
  ],
  sma: [
    {
      name: 'includeDeceased',
      type: 'Radio',
      options: ['yes', 'no'],
    },
  ],
  dmd: [
    {
      name: 'includeDeceased',
      type: 'Radio',
      options: ['yes', 'no'],
    },
  ],
  huntington: [
    {
      name: 'includeDeceased',
      type: 'Radio',
      options: ['yes', 'no'],
    },
  ],
  epilepsy: [
    {
      name: 'includeDeceased',
      type: 'Radio',
      options: ['yes', 'no'],
    },
  ],
  sleepApnea: [
    {
      name: 'gender',
      type: 'Radio',
      options: ['male', 'female'],
    },
    {
      name: 'age',
      type: 'RangeSlider',
      min: 0,
      max: 100,
      showRangeText: true,
      marks: [{ value: 33 }, { value: 66 }],
    },
    {
      name: 'professionalDriving',
      type: 'CheckboxSingle',
      option: 'true',
      optionFormatter: () => fm('general.true'),
    },
    {
      name: 'diagnosis',
      type: 'Checkbox',
      options: ['sleepApneaSuspicion', 'respiratoryFailureSuspicion', 'G47.3', 'J96.1', 'J96.9'],
    },
    {
      name: 'diagnosisDate',
      type: 'Custom',
    },
    {
      name: 'medication',
      type: 'Custom',
    },
    {
      name: 'treatment',
      type: 'Custom',
    },
    {
      name: 'latestBMI',
      type: 'RangeSlider',
      min: 0,
      max: 60,
    },
    {
      name: 'includeDeceased',
      type: 'Radio',
      options: ['yes', 'no'],
    },
  ],
  parkinson: [
    {
      name: 'gender',
      type: 'Radio',
      options: ['male', 'female'],
    },
    {
      name: 'age',
      type: 'RangeSlider',
      min: 0,
      max: 100,
      showRangeText: true,
      marks: [{ value: 33 }, { value: 66 }],
    },
    {
      name: 'diagnosis',
      type: 'MultiSelect',
      options: [
        'G20',
        'G90.3',
        'G23.2',
        'G23.3',
        'G23.1',
        'G25.9',
        'G31.0',
        'F02.8*G31.8',
        'F00.2*G30.8',
        'F02.0*G31.0',
        'G25',
        'G25.0',
        'G25.1#',
        'G25.2',
        'G25.3#',
        'G25.4#',
        'G25.5',
        'G25.6#',
        'G25.8',
      ],
      optionFormatter: (name: string | number): string | JSX.Element => `${name}`,
      tooltips: [
        { index: 0, message: fm('diagnosis.parkinsonType.name.PD') },
        { index: 1, message: fm('diagnosis.parkinsonType.name.MSA') },
        { index: 2, message: fm('diagnosis.parkinsonType.name.MSA') },
        { index: 3, message: fm('diagnosis.parkinsonType.name.MSA') },
        { index: 4, message: fm('diagnosis.parkinsonType.name.PSP') },
        { index: 5, message: fm('diagnosis.parkinsonType.name.CBD') },
        { index: 6, message: fm('diagnosis.parkinsonType.name.CBD') },
        { index: 7, message: fm('diagnosis.parkinsonType.name.DLB') },
        { index: 8, message: fm('diagnosis.parkinsonType.name.DLB') },
        { index: 9, message: fm('diagnosis.parkinsonType.name.FTD') },
        { index: 10, message: fm('diagnosis.parkinsonType.opts.otherExtraPyramidalDisorders') },
        { index: 11, message: fm('diagnosis.parkinsonType.opts.otherExtraPyramidalDisorders') },
        { index: 12, message: fm('diagnosis.parkinsonType.opts.otherExtraPyramidalDisorders') },
        { index: 13, message: fm('diagnosis.parkinsonType.opts.otherExtraPyramidalDisorders') },
        { index: 14, message: fm('diagnosis.parkinsonType.opts.otherExtraPyramidalDisorders') },
        { index: 15, message: fm('diagnosis.parkinsonType.opts.otherExtraPyramidalDisorders') },
        { index: 16, message: fm('diagnosis.parkinsonType.opts.otherExtraPyramidalDisorders') },
        { index: 17, message: fm('diagnosis.parkinsonType.opts.otherExtraPyramidalDisorders') },
        { index: 18, message: fm('diagnosis.parkinsonType.opts.otherExtraPyramidalDisorders') },
      ],
      style: { display: 'flex', justifyContent: 'center', width: '7rem' },
    },
    {
      name: 'diagnosisDate',
      type: 'Custom',
    },
    {
      name: 'medication',
      type: 'Custom',
    },
    {
      name: 'currentLEDD',
      type: 'Custom',
    },
    {
      name: 'dbsTreatment',
      type: 'Checkbox',
      options: ['ongoing', 'ended'],
      optionFormatter: (name: string | number) => fm(`extendedSearch.opts.${name}`),
    },
    {
      name: 'thalamotomyOrHIFU',
      type: 'Checkbox',
      options: ['thalamotomyDone', 'hifuDone'],
      optionFormatter: (name: string | number) => fm(`extendedSearch.${name}`),
    },
    {
      name: 'currentHoehnYahr',
      type: 'MultiSelect',
      options: [0, 1, 1.5, 2, 2.5, 3, 4, 5],
      tooltips: [
        { index: 0, message: fm('updrs.opts.v.0') },
        { index: 1, message: fm('updrs.opts.v.1') },
        { index: 2, message: fm('updrs.opts.v.1.5') },
        { index: 3, message: fm('updrs.opts.v.2') },
        { index: 4, message: fm('updrs.opts.v.2.5') },
        { index: 5, message: fm('updrs.opts.v.3') },
        { index: 6, message: fm('updrs.opts.v.4') },
        { index: 7, message: fm('updrs.opts.v.5') },
      ],
      style: { display: 'flex', justifyContent: 'center', width: '2rem' },
    },
    {
      name: 'includeDeceased',
      type: 'Radio',
      options: ['yes', 'no'],
    },
  ],
  ninmt: [
    {
      name: 'includeDeceased',
      type: 'Radio',
      options: ['yes', 'no'],
    },
  ],
  mgravis: [
    {
      name: 'gender',
      type: 'Radio',
      options: ['male', 'female'],
    },
    {
      name: 'age',
      type: 'RangeSlider',
      min: 0,
      max: 100,
      showRangeText: true,
      marks: [{ value: 33 }, { value: 66 }],
    },
    {
      name: 'diagnosis',
      type: 'Checkbox',
      options: ['G70.0', 'G73.1'],
      optionFormatter: (name: string | number): string | JSX.Element => `${name}`,
    },
    {
      name: 'diagnosisDate',
      type: 'Custom',
    },
    {
      name: 'medication',
      type: 'Custom',
    },
    {
      name: 'includeDeceased',
      type: 'Radio',
      options: ['yes', 'no'],
    },
  ],
});

/**
 * Extended search field component
 */
export const ExtendedSearchField = ({
  platform,
  field,
  searchFields,
  onChange,
  medicationTools,
}: IExtendedSearchField): JSX.Element => {
  const { search, changeMedication, removeMedication } = medicationTools;

  const onChangeMedicationCriteria = (i: number) => (values: TOnChangeValues) => {
    const name = Object.keys(values)[0];
    const value = values[name];
    const newMedicationCriteria =
      !Array.isArray(searchFields.medication) || searchFields.medication.length === 0
        ? [{ medication: null }]
        : JSON.parse(JSON.stringify(searchFields.medication));
    if (['specificMedication', 'specificAtc'].includes(name) && (!Array.isArray(value) || value.length === 0)) {
      newMedicationCriteria[i][name] = 'noCropping';
    } else {
      newMedicationCriteria[i][name] = value;
    }
    if (name === 'medication' && !newMedicationCriteria[i].status) {
      newMedicationCriteria[i].status = 'noCropping';
    }
    // Reorder key-value pairs for proper display
    const ordered: typeof newMedicationCriteria = {};
    ['medication', 'specificMedication', 'specificAtc', 'status'].forEach((key) => {
      const k = key as keyof typeof newMedicationCriteria;
      if (newMedicationCriteria[i][k]) ordered[k] = newMedicationCriteria[i][k];
    });
    newMedicationCriteria[i] = ordered;
    onChange({ medication: newMedicationCriteria });
  };

  const onChangeTreatmentCriteria = (i: number) => (values: TOnChangeValues) => {
    const name = Object.keys(values)[0];
    const value = values[name];
    const newTreatmentCriteria =
      !Array.isArray(searchFields.treatment) || searchFields.treatment.length === 0
        ? [{ treatment: null }]
        : JSON.parse(JSON.stringify(searchFields.treatment));
    if (name === 'treatment' && (!Array.isArray(value) || value.length === 0)) {
      newTreatmentCriteria[i][name] = 'noCropping';
    } else {
      newTreatmentCriteria[i][name] = value;
    }
    if (
      name === 'treatment' &&
      (!newTreatmentCriteria[i].status ||
        !Array.isArray(value) ||
        value.length === 0 ||
        (value.length === 1 && value[0] === 'surgicalTreatment'))
    ) {
      newTreatmentCriteria[i].status = 'noCropping';
    }
    onChange({ treatment: newTreatmentCriteria });
  };

  const value = searchFields[field.name as keyof typeof searchFields];

  switch (field.type) {
    case 'Custom': {
      switch (field.name) {
        case 'diagnosisDate': {
          switch (platform) {
            case 'ms':
            case 'parkinson':
            case 'sleepApnea':
            case 'mgravis': {
              return (
                <React.Fragment>
                  {['diagnosisMinDate', 'diagnosisMaxDate'].map((name, index) => (
                    <div key={index} style={{ marginBottom: index === 0 ? '1rem' : undefined }}>
                      <div style={{ fontSize: '1.4rem', color: colors.tertiaryText }}>
                        {fm(`extendedSearch.${name}`)}
                      </div>
                      <InputHandler
                        type="PartialDate"
                        editing={true}
                        name={name}
                        formData={{
                          onChange: onChange,
                          document: {
                            [name]: value === 'noCropping' ? undefined : value?.[name as keyof typeof value],
                          },
                        }}
                        dateHook={
                          {
                            diagnosisMinDate: {
                              dateHookFloor: subtractYears(nowPartialDate(), 100),
                              dateHookCeiling:
                                value === 'noCropping' ? undefined : value?.['diagnosisMaxDate' as keyof typeof value],
                            },
                            diagnosisMaxDate: {
                              dateHookFloor:
                                value === 'noCropping'
                                  ? subtractYears(nowPartialDate(), 100)
                                  : value?.['diagnosisMinDate' as keyof typeof value],
                            },
                          }[name]
                        }
                      />
                    </div>
                  ))}
                </React.Fragment>
              );
            }
          }
          break;
        }
        case 'medication': {
          return (
            <React.Fragment>
              {(Array.isArray(searchFields.medication) ? searchFields.medication : [{ medication: null }]).map(
                (m, i, arr) => (
                  <React.Fragment key={`searchCriteria${i}`}>
                    <div
                      style={{
                        backgroundColor: colors.lightestGray,
                        padding: '1rem',
                        marginBottom: i === arr.length - 1 ? '1rem' : undefined,
                      }}
                    >
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ marginBottom: '1rem', color: colors.tertiaryText }}>
                          {fm('extendedSearch.searchCriteriaTitle')}
                          {` ${i + 1}`}
                        </div>
                        {arr.length > 1 && (
                          <ClearIconStyled
                            onClick={() =>
                              Array.isArray(searchFields.medication) &&
                              onChange({ medication: searchFields.medication.filter((_, index) => index !== i) })
                            }
                          />
                        )}
                      </div>
                      <InputHandler
                        type="Radio"
                        editing={true}
                        name="medication"
                        formData={{
                          onChange: (values: TOnChangeValues) => onChangeMedicationCriteria(i)(values),
                          document: { medication: m.medication },
                        }}
                        options={[
                          ...(platform === 'ms'
                            ? ['anyMedication', 'noMedication']
                            : platform === 'sleepApnea'
                              ? ['specificAtc']
                              : []),
                          'specificMedication',
                        ]}
                        optionFormatter={(name: string | number): string | JSX.Element =>
                          fm(`extendedSearch.opts.${name}`)
                        }
                      />
                      {m.medication === 'specificAtc' && (
                        <React.Fragment>
                          <div style={{ fontSize: '1.4rem', color: colors.tertiaryText, marginBottom: '1rem' }}>
                            {fm('extendedSearch.specificAtc')}
                          </div>
                          <InputHandler
                            type="Checkbox"
                            editing={true}
                            name="specificAtc"
                            formData={{
                              onChange: onChangeMedicationCriteria(i),
                              document: {
                                specificAtc: m.specificAtc,
                              },
                            }}
                            options={atcCodesByPlatform['sleepApnea'].filter(
                              (c) =>
                                c !== 'C01CA' &&
                                (!Array.isArray(searchFields.diagnosis) || !searchFields.diagnosis.includes('G47.3')
                                  ? c !== 'N06BA14'
                                  : true),
                            )}
                            optionFormatter={(name: string | number): string | JSX.Element => (
                              <React.Fragment>
                                {name} {fm(`graph.atcOpts.sleepApnea.${name}`)}
                              </React.Fragment>
                            )}
                          />
                        </React.Fragment>
                      )}
                      {m.medication === 'specificMedication' && (
                        <React.Fragment>
                          <div style={{ fontSize: '1.4rem', color: colors.tertiaryText, marginBottom: '1rem' }}>
                            {fm('extendedSearch.specificMedication')}
                          </div>
                          <ItemList
                            items={
                              Array.isArray(m.specificMedication)
                                ? m.specificMedication.map((med: string, index: number, array: string[]) => {
                                    return {
                                      firstItem: med,
                                      itemOnClick: (): void =>
                                        removeMedication(
                                          onChangeMedicationCriteria(i),
                                          m.specificMedication ?? 'noCropping',
                                          index,
                                          'specificMedication',
                                        ),
                                      style: {
                                        fontWeight: 600,
                                        backgroundColor: colors.lightGray,
                                        paddingLeft: '0.8rem',
                                        alignItems: 'center',
                                        marginBottom: index === array.length - 1 ? '1rem' : undefined,
                                      },
                                    };
                                  })
                                : []
                            }
                            separator={{
                              content: fm('general.or'),
                              style: { padding: '0.5rem 0 0.5rem 0.8rem', textTransform: 'uppercase' },
                            }}
                            button={
                              <SearchDialog
                                formData={{
                                  onChange: changeMedication(
                                    onChangeMedicationCriteria(i),
                                    m.specificMedication ?? 'noCropping',
                                    'specificMedication',
                                  ),
                                  document: m.specificMedication,
                                }}
                                searchFn={search}
                                resultToDoc={{
                                  name: 'medicationName',
                                  substanceString: 'medicationSubstances',
                                  dosageForm: 'dosageForm',
                                }}
                                title={'medication.chooseMedication'}
                                info={'medication.medDialogInfoText'}
                                openCloseButton={{ width: 12, height: 3, fontSize: 16, text: 'general.new' }}
                              />
                            }
                          />
                        </React.Fragment>
                      )}
                      {m.medication && (
                        <React.Fragment>
                          <div style={{ fontSize: '1.4rem', color: colors.tertiaryText, margin: '1rem 0 1rem 0' }}>
                            {fm('extendedSearch.status')}
                          </div>
                          <InputHandler
                            type="Radio"
                            editing={true}
                            name="status"
                            formData={{
                              onChange: onChangeMedicationCriteria(i),
                              document: { status: m.status },
                            }}
                            options={['noCropping', 'inUse', 'earlierInUse'].concat(
                              m.medication === 'specificMedication' ? ['notInUse'] : [],
                            )}
                            optionFormatter={(name: string | number): string | JSX.Element =>
                              fm(`extendedSearch.opts.${name}`)
                            }
                          />
                        </React.Fragment>
                      )}
                    </div>
                    {arr.length > 1 && i < arr.length - 1 && (
                      <div style={{ padding: '1rem', textTransform: 'uppercase' }}>{fm('general.and')}</div>
                    )}
                  </React.Fragment>
                ),
              )}
              <div style={{ marginTop: '2rem', justifyContent: 'center' }}>
                <ActionButton
                  text={'extendedSearch.addSearchCriteria'}
                  onClick={() => {
                    const newMedication = JSON.parse(
                      JSON.stringify(
                        Array.isArray(searchFields.medication) && searchFields.medication.length > 0
                          ? searchFields.medication
                          : [{ medication: null }],
                      ),
                    );
                    newMedication.push({ medication: null });
                    onChange({ medication: newMedication });
                  }}
                  width={16}
                  height={3}
                  fontSize={16}
                />
              </div>
            </React.Fragment>
          );
        }
        case 'relapseDuringLast12Months': {
          switch (platform) {
            case 'ms': {
              return (
                <React.Fragment>
                  <InputHandler
                    type="Radio"
                    editing={true}
                    name={field.name}
                    formData={{
                      onChange: onChange,
                      document: { [field.name]: value === 'noCropping' ? undefined : value },
                    }}
                    options={['yes', 'no']}
                    optionFormatter={(name: string | number): string | JSX.Element => fm(`extendedSearch.opts.${name}`)}
                  />
                  {searchFields.relapseDuringLast12Months === 'yes' && (
                    <React.Fragment>
                      <div style={{ fontSize: '1.4rem', color: colors.tertiaryText, marginBottom: '1rem' }}>
                        {fm('extendedSearch.numberOfRelapses')}
                      </div>
                      <InputHandler
                        type="Radio"
                        editing={true}
                        name="numberOfRelapses"
                        formData={{
                          onChange: onChange,
                          document: {
                            numberOfRelapses:
                              searchFields.numberOfRelapses === 'noCropping'
                                ? undefined
                                : searchFields.numberOfRelapses,
                          },
                        }}
                        options={['1', '2', '3', 'more']}
                        optionFormatter={(name: string | number): string | JSX.Element =>
                          fm(`extendedSearch.opts.${name}`)
                        }
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              );
            }
          }
          break;
        }
        case 'currentLEDD':
        case 'latestBMI': {
          const value = searchFields[field.name as keyof typeof searchFields];
          const minMax =
            field.name === 'currentLEDD'
              ? ['minLEDD', 'maxLEDD']
              : field.name === 'latestBMI'
                ? ['minBMI', 'maxBMI']
                : [];
          return (
            <React.Fragment>
              {minMax.map((name, i) => (
                <React.Fragment key={i}>
                  <InputHandler
                    type="NumberField"
                    editing={true}
                    name={name}
                    formData={{
                      document: {
                        [name]: value === 'noCropping' ? undefined : value?.[name as keyof typeof value],
                      },
                      onChange: onChange,
                    }}
                    min={0}
                    placeholder={`extendedSearch.${name}Placeholder`}
                    width={9}
                  />
                  {i === 0 && ' - '}
                </React.Fragment>
              ))}
            </React.Fragment>
          );
        }
        case 'treatment': {
          return (
            <React.Fragment>
              {(Array.isArray(searchFields.treatment) ? searchFields.treatment : [{ treatment: null }]).map(
                (t, i, arr) => (
                  <React.Fragment key={`searchCriteria${i}`}>
                    <div
                      style={{
                        backgroundColor: colors.lightestGray,
                        padding: '1rem',
                        marginBottom: i === arr.length - 1 ? '1rem' : undefined,
                      }}
                    >
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ marginBottom: '1rem', color: colors.tertiaryText }}>
                          {fm('extendedSearch.searchCriteriaTitle')}
                          {` ${i + 1}`}
                        </div>
                        {arr.length > 1 && (
                          <ClearIconStyled
                            onClick={() =>
                              Array.isArray(searchFields.treatment) &&
                              onChange({ treatment: searchFields.treatment.filter((_, index) => index !== i) })
                            }
                          />
                        )}
                      </div>
                      <InputHandler
                        type="Checkbox"
                        editing={true}
                        name="treatment"
                        formData={{
                          onChange: (values: TOnChangeValues) => onChangeTreatmentCriteria(i)(values),
                          document: { treatment: t.treatment },
                        }}
                        options={[
                          'patientDoesNotWantRespiratorySupportTherapy',
                          'oxygenTherapy',
                          'hfncTherapy',
                          'papTherapy',
                          'madTherapy',
                          'surgicalTreatment',
                          'postureTreatment',
                        ]}
                        optionFormatter={(name: string | number): string | JSX.Element =>
                          fm(`extendedSearch.opts.${name}`)
                        }
                      />
                      {t.treatment && Array.isArray(t.treatment) && (
                        <React.Fragment>
                          <div style={{ fontSize: '1.4rem', color: colors.tertiaryText, margin: '1rem 0 1rem 0' }}>
                            {fm('extendedSearch.status')}
                          </div>
                          <InputHandler
                            type="Radio"
                            editing={true}
                            name="status"
                            formData={{
                              onChange: onChangeTreatmentCriteria(i),
                              document: { status: t.status },
                            }}
                            options={['noCropping', 'ongoing', 'ended']}
                            optionFormatter={(name: string | number): string | JSX.Element =>
                              fm(`extendedSearch.opts.${name}`)
                            }
                            disabledOptions={
                              t.treatment.length > 0 &&
                              !(t.treatment.length === 1 && t.treatment[0] === 'surgicalTreatment')
                                ? undefined
                                : ['noCropping', 'ongoing', 'ended']
                            }
                          />
                        </React.Fragment>
                      )}
                    </div>
                    {arr.length > 1 && i < arr.length - 1 && (
                      <div style={{ padding: '1rem', textTransform: 'uppercase' }}>{fm('general.and')}</div>
                    )}
                  </React.Fragment>
                ),
              )}
              <div style={{ marginTop: '2rem', justifyContent: 'center' }}>
                <ActionButton
                  text={'extendedSearch.addSearchCriteria'}
                  onClick={() => {
                    const newTreatment = JSON.parse(
                      JSON.stringify(
                        Array.isArray(searchFields.treatment) && searchFields.treatment.length > 0
                          ? searchFields.treatment
                          : [{ treatment: null }],
                      ),
                    );
                    newTreatment.push({ treatment: null });
                    onChange({ treatment: newTreatment });
                  }}
                  width={16}
                  height={3}
                  fontSize={16}
                />
              </div>
            </React.Fragment>
          );
        }
      }
      break;
    }
    default: {
      return (
        <div style={{ paddingRight: field.type === 'RangeSlider' ? '4.2rem' : undefined }}>
          <InputHandler
            type={field.type as any}
            editing={true}
            name={field.name}
            formData={{
              onChange: onChange,
              document: {
                [field.name]:
                  value === 'noCropping'
                    ? field.type === 'Checkbox'
                      ? []
                      : undefined
                    : field.type === 'RangeSlider'
                      ? [Object.values(value as TAnyObject)[0], Object.values(value as TAnyObject)[1]]
                      : value,
              },
            }}
            options={field.options}
            option={field.option}
            optionFormatter={
              field.optionFormatter
                ? field.optionFormatter
                : (name: string | number): string | JSX.Element => fm(`extendedSearch.opts.${name}`)
            }
            min={field.min}
            max={field.max}
            tooltips={field.tooltips}
            style={field.style}
            marks={field.marks}
            showRangeText={field.showRangeText}
            placeholder={field.placeholder}
          />
        </div>
      );
    }
  }
  return <></>;
};

interface IExtendedSearchField {
  platform: string;
  field: TESSearchField;
  searchFields: TESSearchFields;
  onChange: (e: TOnChangeValues) => void;
  medicationTools: {
    search: (currentSearchString: string) => Promise<any>;
    changeMedication: (
      onChange: (e: TOnChangeValues) => void,
      medications: 'noCropping' | Array<string>,
      name: string,
    ) => (values: TOnChangeValues) => void;
    removeMedication: (
      onChange: (values: TOnChangeValues) => void,
      medications: 'noCropping' | Array<string>,
      index: number,
      name: string,
    ) => void;
  };
}
