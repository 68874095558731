import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'Store/index';

import ContentWrapper from '../ContentWrapper';

import { styleHeader } from '../../config/theme/componentTheme';

import { Topic } from '@mui/icons-material';

import logo from './images/StellarQlogo.png';
import { actions } from '../../store/session';

import UserInfoLogout from './components/UserInfoMenuArea';
import PlatformMenu from './components/PlatformMenu';
import { PatientInfoAlerts } from './components/PatientInfoAlerts';
import { Container, Item } from '../Grid';
import ContactForm from './components/ContactForm';
import { sortPartialDate } from 'neuro-utils';
import { assertCapabilities } from 'Store/index';
import PlatformCapabilities from '../../config/capabilities';
import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';
import theme from '../../config/theme/muiTheme';
import colors from '../../config/theme/colors';
import { localStorageSave } from 'Utility/localStorage';
import { sortPlatformsByLocale } from 'Routes/_Patient/SearchAndCreation/PlatformSelectVisitReason/utils';
import { find, includes } from 'ramda';
import { getEnabledDocuments } from 'Utility/randomUtil';
import { makeLog } from 'Utility/logger';
import CircularProgressLocal from 'Components/CircularProgress';
import { Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/system';

const AboutDialog = React.lazy(() => import('./components/AboutDialog'));
const SettingsDialog = React.lazy(() => import('./components/SettingsDialog'));
const StatsModal = React.lazy(() => import('../StatisticsModal'));

const StyledHeader = styled('div')({
  minWidth: '89.4rem',
  width: '100%',
  height: styleHeader.height,
  background: styleHeader.backgroundColor,
  backgroundImage: "url('/assets/images/headerThreads.svg')",
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  color: '#fff',
  fontSize: '1.4rem',
  fontWeight: 600,
});

const Logo = styled(({ ...other }) => <img src={logo} {...other} />)({
  width: 'auto',
  height: '5rem',
});

const PlatformChooserButton = styled('button', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'disabled' })(
  ({ open, disabled }: { open: boolean; disabled: boolean }) => ({
    width: '25rem',
    height: '3.2rem',
    padding: '0rem',
    cursor: disabled ? 'default' : 'pointer',
    borderTopLeftRadius: '0.3rem',
    borderTopRightRadius: '0.3rem',
    borderBottomLeftRadius: open ? 0 : '0.3rem',
    borderBottomRightRadius: open ? 0 : '0.3rem',
    backgroundColor: colors.appBlue.lightest,
    zIndex: 15,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    border: '0rem',
    color: colors.secondary,
  }),
);

const PlatformText = styled('div')({
  color: colors.secondary,
  fontSize: '1.6rem',
  textAlign: 'left',
  maxWidth: '17rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const PlatformChooserArrow = styled('div')({
  margin: '0 1rem',
});

const PlatformChooserBar = styled('span', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ open }: { open: boolean }) => ({
    position: 'relative',
    display: 'block',
    height: '2px',
    background: colors.secondary,
    transition: 'transform 100ms, position 500ms',
    '&:first-of-type': {
      transform: 'rotate(45deg)',
      top: '2px',
      left: `${open ? -1 : -5}px`,
      width: `${open ? 12 : 9}px`,
    },
    '&:nth-of-type(2)': {
      transform: 'rotate(-45deg)',
      top: '0px',
      left: `${open ? -1 : 0}px`,
      width: `${open ? 12 : 9}px`,
    },
  }),
);

const ArrowDropDownLines = ({ open = false }: { open: boolean }) => (
  <PlatformChooserArrow>
    <PlatformChooserBar open={open} />
    <PlatformChooserBar open={open} />
  </PlatformChooserArrow>
);
// Return match name, unless theres subTypes then match all subTypes
const editingPredicate = (d: { name: string; id: string }, route: string, session: ISessionStore): boolean => {
  const enabledName = find((name) => name.name === route, getEnabledDocuments(session));
  if (enabledName && enabledName.subTypes) {
    return includes(d.name, enabledName.subTypes);
  } else {
    return d.name === route;
  }
};

const Header = ({ session, capabilityGroups = {} }: IOwnProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });
  // State and anchor for showing menu for platforms
  const [platformMenuAnchor, setPlatformMenuAnchor] = React.useState<SVGSVGElement | HTMLElement | null>(null);
  const toggleShowPM = (e: React.MouseEvent<SVGSVGElement | HTMLElement>): void => {
    setPlatformMenuAnchor(!platformMenuAnchor ? e.currentTarget : null);
  };

  // State and anchor for showing menu for additional header functions (logout, search, etc)
  const [showUserMenu, setShowUserMenu] = React.useState<SVGSVGElement | HTMLElement | null>(null);
  const toggleUserMenu = (e: React.MouseEvent<SVGSVGElement | HTMLElement>): void => {
    setShowUserMenu(!showUserMenu && e.currentTarget.getAttribute('type') === 'button' ? e.currentTarget : null);
  };

  const [settingsShow, setSettingsShow] = React.useState<boolean>(false);
  const setUserSettingsShow = (state: boolean) => (): void => {
    setSettingsShow(state);
  };

  // Feedback dialog opening
  const [feedbackDialogOpen, setFeedbackDialogOpen] = React.useState<boolean>(false);
  const setFeedbackShow = (state: boolean) => (): void => {
    setFeedbackDialogOpen(state);
  };

  // Infodialog handler (about StellarQ, etc)
  const [infoDialogOpen, setInfoDialogOpen] = React.useState<boolean>(false);
  const setInfoShow = (state: boolean) => (): void => {
    setInfoDialogOpen(state);
  };

  // statistics modal state
  const [statsModalOpen, setStatsModalOpen] = React.useState<boolean>(false);
  /** Get a callback for toggling statistics modal. */
  const getStatsToggler = (state: boolean) => (): void => {
    setStatsModalOpen(state);
  };

  const dispatch = useDispatch();

  const onChangePlatform = (name: Platform) => (): void => {
    // Record platform changes
    makeLog(
      'Info',
      {
        name: 'User changed platform',
        message: `User ${session?.data?.useruuid} from org ${session?.data?.orgid} changed platform for patient ${session?.data?.patientid} from platform ${session?.platforms?.selected} to platform ${name}`,
      },
      {
        userid: session?.data?.useruuid,
        orgid: session?.data?.orgid,
        patientid: session?.data?.patientid,
        platform: name,
        previousPlatform: session?.platforms?.selected,
      },
    );

    const platform = ['ms', 'keo', 'reo', 'nmosd'].includes(name) ? 'ms' : name;
    // Save selected platform to redux
    dispatch(actions.setPlatform(platform));
    localStorageSave('platform', platform);
    setPlatformMenuAnchor(null);
  };

  // Get documents for alerts
  const sortedAndMergedDocs = useSelector((s: { documents: IDocumentStore }) => s.documents.sortedAndMergedDocuments);

  type Platforms = { available: Platform[]; selected?: Platform; preferred?: Platform[] };

  // Map platforms to different ones if certain conditions are fulfilled
  const platformMapper = (platforms?: Platforms): Platforms | undefined => {
    const diagnosisDocs = (sortedAndMergedDocs || []).filter((d) => d && d._type === 'diagnosis') as IDiagnosis[];

    diagnosisDocs.sort((a, b) => sortPartialDate(b.date, a.date));

    const isLicensedNMOSD = assertCapabilities([PlatformCapabilities.NMOSD_CAPABILITES], capabilityGroups);

    // MS, KEO, REO and NMOSD
    const msPlatform = diagnosisDocs.some((d) => d.diagnosis === 'G35')
      ? 'ms'
      : diagnosisDocs.some((d) => d.diagnosis === 'G36.0' && isLicensedNMOSD)
        ? 'nmosd'
        : diagnosisDocs.find(
              (d) =>
                ['G36.9', 'G37.9', 'G36.0', 'radiologicallyIsolatedSyndrome'].includes(d.diagnosis || '') &&
                !isLicensedNMOSD,
            )
          ? diagnosisDocs.find(
              (d) =>
                ['G36.9', 'G37.9', 'G36.0', 'radiologicallyIsolatedSyndrome'].includes(d.diagnosis || '') &&
                !isLicensedNMOSD,
            )?.diagnosis === 'radiologicallyIsolatedSyndrome'
            ? 'reo'
            : 'keo'
          : 'ms';

    if (platforms) {
      const newPlatforms = JSON.parse(JSON.stringify(platforms));
      newPlatforms.available = newPlatforms.available?.map((p: string) => (p === 'ms' ? msPlatform : p));
      newPlatforms.selected = newPlatforms.selected === 'ms' ? msPlatform : newPlatforms.selected;
      newPlatforms.preferred = newPlatforms.preferred?.map((p: string) => (p === 'ms' ? msPlatform : p));
      return newPlatforms;
    }
    return platforms;
  };

  const { available, selected, preferred } = platformMapper(session?.platforms) || {
    available: [],
    selected: undefined,
  };

  const editingDocuments = useSelector((s: IState) => s.form.editingDocuments);
  const currentRoute = useLocation();
  const route = currentRoute.pathname.split('/')[1];
  const thisEditingDocument = session && editingDocuments.find((ed) => editingPredicate(ed, route, session));

  const patientData = useSelector((s: IState) => s.patient.data);

  return (
    <StyledHeader>
      <ContentWrapper>
        <Container alignItems="center" direction="row" style={{ height: '100%', width: '100%' }}>
          <Item xs={2} md={1.5}>
            <Link style={{ display: 'flex', width: 129.5 }} to="/">
              <Logo />
            </Link>
          </Item>

          <Item xs={8} md={9.5}>
            <Container alignItems="center">
              <Item xs={true} md={7}>
                {patientData && <PatientInfoAlerts patientData={patientData} />}
              </Item>
              <Item xs={'auto'} md={5} justifyContent="center" style={{ display: 'flex' }}>
                {selected && available.length > 1 ? (
                  <PlatformChooserButton
                    onClick={thisEditingDocument ? undefined : toggleShowPM}
                    open={Boolean(platformMenuAnchor)}
                    disabled={!!thisEditingDocument}
                  >
                    <Container alignItems="center" style={{ height: '100%', userSelect: 'none' }}>
                      <Item>
                        <Topic
                          color="inherit"
                          style={{
                            display: 'block',
                            padding: '0 1rem',
                          }}
                        />
                      </Item>
                      <Item xs={true}>
                        <PlatformText>
                          <FormattedMessage id={`header.platforms.${selected}`} />
                        </PlatformText>
                      </Item>
                      {!thisEditingDocument && (
                        <Item>
                          <ArrowDropDownLines open={!!platformMenuAnchor} />
                        </Item>
                      )}
                    </Container>
                    <PlatformMenu
                      platformList={sortPlatformsByLocale(available, fm) || []}
                      preferred={sortPlatformsByLocale(preferred, fm) || []}
                      onChangePlatform={onChangePlatform}
                      anchorEle={platformMenuAnchor}
                    />
                  </PlatformChooserButton>
                ) : (
                  <PlatformText style={{ color: colors.white }}>
                    <Container alignItems="center" style={{ textAlign: 'center' }}>
                      <Item xs={true}>
                        {selected ? (
                          <FormattedMessage id={`header.platforms.${selected}`} />
                        ) : !selected && session?.data?.visitid ? (
                          <FormattedMessage id={`header.platforms.noDiagnosis`} />
                        ) : (
                          ''
                        )}
                      </Item>
                    </Container>
                  </PlatformText>
                )}
              </Item>
            </Container>
          </Item>

          <Item xs={2} md={1}>
            {session?.data && (
              <UserInfoLogout
                session={session}
                dispatch={dispatch}
                toggleUserMenu={toggleUserMenu}
                showMenu={showUserMenu}
                setUserSettingsShow={setUserSettingsShow}
                setFeedbackShow={setFeedbackShow}
                setInfoShow={setInfoShow}
                getStatsToggler={getStatsToggler}
              />
            )}
          </Item>
        </Container>

        {settingsShow || feedbackDialogOpen || infoDialogOpen || statsModalOpen ? (
          <React.Suspense
            fallback={
              <Dialog open={true} maxWidth={'xs'}>
                <DialogContent>
                  <CircularProgressLocal size={'3rem'} />
                </DialogContent>
              </Dialog>
            }
          >
            <SettingsDialog openDialog={settingsShow} setOpenDialog={setUserSettingsShow} />
            <ContactForm openDialog={feedbackDialogOpen} setOpenDialog={setFeedbackShow} />
            <AboutDialog openDialog={infoDialogOpen} setOpenDialog={setInfoShow} />
            <StatsModal modalOpen={statsModalOpen} getModalToggler={getStatsToggler} />
          </React.Suspense>
        ) : (
          <></>
        )}
      </ContentWrapper>
    </StyledHeader>
  );
};

interface IOwnProps extends ICapabilityContextProps {
  session?: ISessionStore;
}

export default withCapabilities(Header);
