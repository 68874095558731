/**
 * Some combined actions that can affect multiple stores at once
 */

import { TDispatch } from '.';
import { actions as appointmentActions } from './appointments';
import { reduxActions as documentActions } from './documents/actions';
import { actions as myDocumentActions } from './myapp/actions';
import { actions as readonlyDocumentActions } from './readonly/actions';
import { reduxActions as patientActions } from './patient';
import { actions as sessionActions } from './session';

/** Clear all patient documents, data and settings from redux store */
export const clearAllPatientDataAndSettings = () => (dispatch: TDispatch) => {
  dispatch(documentActions.clearAction()); // { isLoading: undefined, isCreating: undefined, documents: undefined, sortedAndMergedDocuments: undefined }
  dispatch(myDocumentActions.clearDocuments()); // { mysqUserId: null, sentInvites: [], sortedAndMergedDocuments: [], taskLists: [] }
  dispatch(readonlyDocumentActions.clearData()); // { documents: [] }
  dispatch(patientActions.clearData()); // { data: undefined, grants: undefined, delegates: undefined }
  dispatch(appointmentActions.clearAppointmentsAction()); // { appointments: [] }
  dispatch(sessionActions.setGraphSelections({ timeframe: null, timeframeLength: null, selectedGraph: null }));
};
