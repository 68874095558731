import { isIE } from '../../utility/isIE';
import colors from './colors';

const globalStyle = {
  body: {
    color: colors.primaryText,
    backgroundColor: colors.defaultBackground,
    margin: 0,
    ...(isIE() && { overflowX: 'hidden' }),
    fontSize: '1.6rem',
    // line-height: 2rem;
  },
  html: {
    fontFamily: 'Titillium Web',
    fontSize: '10px',
  },
  a: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: colors.primary,
    '&:hover': {
      color: colors.secondary,
    },
  },
  input: {
    '::placeholder': {
      color: `${colors.inputPlaceholder} !important`,
      opacity: '1 !important',
    },
  },
  h1: {
    fontSize: '4.4rem',
    fontWeight: 400,
    lineHeight: '4.8rem',
  },
  h2: {
    fontSize: '2.2rem',
    fontWeight: 600,
    lineHeight: '2.2rem',
  },
  h3: {
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: '1.6rem',
  },
  '.subtitle': {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    lineHeight: '1.6rem',
  },
  '.small': {
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: '1.6rem',
  },
  '.power-bi-container': {
    height: 'calc(100vh - 20rem)',
  },
  '.power-bi-container iframe': {
    border: 'none',
  },
  '.ellipsis': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  '.ellipsis-multiline': {
    display: '-webkit-box',
    WebkitLineClamp: 4,
    /* autoprefixer: ignore next */
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  '.ellipsis-show-hover': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ':hover': {
      overflow: 'visible',
      position: 'absolute',
      backgroundColor: 'white',
      paddingRight: '0.5rem',
      //boxShadow: '0 2px 4px 0 rgba(0,0,0,.28)',
    },
  },
};

export default globalStyle;
